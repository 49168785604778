import axios from "axios";
import React, { Component, useState, useEffect } from "react";
import { Button } from 'rsuite';
import {useSearchParams} from "react-router-dom";
// import { setConstantValue } from "typescript";

export default function LoginForm() {

    const[username,setUsername] = useState("");
    const[password,setPassword] = useState("");
    const[code,setCode] = useState("");
    const [message, setMessage] = useState("");
    const [expire_message, setExpireMessage] = useState("");
    const[error_message,setErrorMessage] = useState("");
    const [searchParams] = useSearchParams();
    const [verified, setVerified] = useState(false);
    const [onTimer, setOnTimer] = useState(false);
    const [generatedCode, setGeneratedCode] = useState();
    const [timerInterval, setTimerInterval] = useState();
    const [currentIP, setCurrentIP] = useState();
    const maxAttempts = 5;

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {setCurrentIP(data.ip)})
            .catch(error => console.error('Error:', error));
    },[])

    const textfieldChangeHandler = (e, fileType) => {
        switch(fileType) {
            case 'username':
                setUsername(e.target.value);
                break;
            case 'password':
                setPassword(e.target.value);
                break;
            case 'code':
                setCode(e.target.value);
                break;
        }
    }

    // Function to update the timer display
    function updateTimer(time) {
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        // Display the timer in the console
        let timer = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        setMessage(`A Verification Code has been sent to your email.`);
        setExpireMessage(`Expires in ${timer}`);
    }

    const startTimer = () => {
        setErrorMessage('')
        // Countdown time 5 mins
        let countdownTime = 5 * 60;
        // Display the initial time
        updateTimer(countdownTime);
        // Update the timer every second
        const intervalId = setInterval(() => {
            // Update the timer display
            countdownTime--;
            updateTimer(countdownTime);
            // Check if the countdown has finished
            if (countdownTime == 0) {
                stopTimer();
                clearInterval(intervalId); // Stop the timer
            }
        }, 1000);
        setTimerInterval(intervalId);
    }

    const stopTimer = () => {
        setMessage('');
        setExpireMessage('');
        setGeneratedCode('');
        setVerified(false);
        setOnTimer(false);
    }

    const getVerificationCode = (e) => {
        e.preventDefault();
        if (!onTimer) {
            axios.post(siteUrl + 'verification', {username: username}).then((response) => {
                let data = response.data;
                if (data.error) {
                    setOnTimer(false);
                    setVerified(false);
                    setErrorMessage('This account has been locked or it does not exist.\nPlease contact the IT department.')
                } else {
                    setOnTimer(true);
                    setVerified(true);
                    setGeneratedCode(data.code);
                    startTimer();
                }
            })
        }
    }

    const verification = (type) => {

        axios.post(siteUrl + 'attempts', {username: username, ip: currentIP}).then((response) => {
            if (response.data.error) {
                setErrorMessage('This account does not exist.')
            } else {
                let attempt = response.data.attempt;
                if (maxAttempts - attempt == 0) {
                    axios.post(siteUrl + 'account-lock', {username: username});
                    setErrorMessage('This account has been locked.\nReason: Exceeded login attempts.\nPlease contact the IT department.');
                    setVerified('');
                    setMessage('');
                    setExpireMessage('');
                    clearInterval(timerInterval);
                } else {
                    if (type == 'code') {
                        setErrorMessage(`Incorrect verification code. ${maxAttempts - attempt} attempt(s) left.`);
                    } else {
                        setErrorMessage(`Incorrect email or password. ${maxAttempts - attempt} attempt(s) left.`);
                    }
                }
            }
        })
    }

    const submitLogin = (e) => {
        e.preventDefault();
        if (!verified || code != generatedCode) {
            if (!verified) {
                setErrorMessage('Get your verification code to login.')
            } else {
                verification('code');
            }
        } else {
            axios.post(siteUrl + 'login', {
                username: username,
                password: password,
                ip: currentIP
              }).then((response) => {
                let data = response.data;
                if(data.error === false && code === generatedCode && code.length === 6 && generatedCode.length === 6) {
                    // login passed;
                    setCode('');
                    setGeneratedCode('');
                    setErrorMessage('');
                    let returnUrl = searchParams.get('redirect');
                    if(returnUrl == null) {
                        returnUrl = siteUrl;
                    } else {
                        returnUrl = siteUrl + returnUrl.replace(/^\//g, ''); // replace to avoid double slashes
                    }                
                    window.location = returnUrl;
                } else {
                    // login failed
                    verification('login');
                }
              }).catch((error) => {
                console.log(error);
                setErrorMessage('Something went wrong')
              });
        }
    }

    return (
        <>
            <form className="login-form-container">
                <fieldset>
                    <div className="login-form-content">
                        <div className="input email login register forget">
                            <input id="email" type="email" name="email" placeholder="Email" required="" onChange={(e) => textfieldChangeHandler(e, 'username')} value={username} disabled={verified}/>
                            <span><i className="fa fa-envelope-o" aria-hidden="true"></i></span>
                        </div>
                        <div className="input password login register">
                            <input id="password" type="password" name="password" placeholder="Password" onChange={(e) => textfieldChangeHandler(e, 'password')} value={password}/>
                            <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                        </div>
                        <div className="input email login register forget" style={{ display: 'flex', alignItems: 'center' }}>
                            <input id="code" type="number" name="code" placeholder="Verification Code" onChange={(e) => textfieldChangeHandler(e, 'code')} value={code} style={{ width: '80%', marginRight: '10px' }} />
                            <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                            <Button style={{backgroundColor:'#e20681', color:'white'}} onClick={(e) => getVerificationCode(e)} disabled={onTimer}>Get Code</Button>
                            {/* <button id="code-login" className="submit input login" onClick={(e) => getVerificationCode(e)} style={{ width: '30%', height: '35px', marginTop:'12px' }}>Get Code</button> */}
                        </div>
                        <p style={{color:'white'}}>{message}</p>
                        <p style={{color:'white'}}>{expire_message}</p>
                        <p style={{color:'white', whiteSpace: 'pre-line' }}>
                            {error_message}
                        </p>
                        <button id="submit-login" className="submit input login" onClick={(e) => submitLogin(e)}>Login</button>
                    </div>
                </fieldset>
            </form>
        </>
        );
}